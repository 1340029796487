import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';

import Layout from '../components/layout';

const Wrapper = styled(Container)`
  padding-bottom: 15rem !important;
  padding-top: 1.7rem !important;
`;

const SnaggyLabs = () => {
  return (
    <Layout>
      <Helmet title={'Project - Snaggy Labs'} />
      <Container className="text-center" style={{ paddingBottom: 0 }}>
        <h3 id="repodev">SNAGGY LABS</h3>

        <StaticImage
          src="../images/snaggylabs.svg"
          className="rounded img-fluid mx-auto"
          alt="Snaggy Labs"
          layout="fixed"
          placeholder="BLURRED"
          width={150}
          height={150}
        />
      </Container>

      <Wrapper>
        <p>
          Hello, welcome to my project page, Snaggy Labs. This project is about
          Mobile Apps development. So, I&apos;ve been learning mobile app
          development since early 2020 (pandemic; lockdown). I&apos;m focusing
          on one of the best platform to create mobile app which is Expo.
        </p>

        <p>
          I have a plan to create educational and/or productivity apps at the
          moment, and will continue to expand to more wider segment like any
          other company or mobile developer.
        </p>

        <address>
          You can contact me via email:{' '}
          <a href="mailto:snag.labs@gmail.com" style={{ color: '#0056b2' }}>
            snag.labs@gmail.com
          </a>
          .<br />
          You may also want to visit:
          <br />
          Snaggy Labs
          <br />
          Jl. Werkudoro Kolojoyo
          <br />
          Demangan, Gondokusuman, Yogyakarta, 55221
          <br />
          Indonesia
        </address>

        <br />
        <hr />
        <p>Visit my playstore page by clicking the following image.</p>
        <a
          href="https://play.google.com/store/apps/dev?id=5589129747248322251"
          target="_blank"
          rel="noreferrer"
        >
          <StaticImage
            src="../images/google-play-badge.jpg"
            className="rounded img-fluid"
            alt="Snaggy Labs"
            layout="fixed"
            placeholder="BLURRED"
            width={250}
            height={150}
          />
        </a>
      </Wrapper>
    </Layout>
  );
};

export default SnaggyLabs;
